.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.app-header {
  width: 100%;
  display: flex;
  padding: 15px;
  justify-content: space-between;
  align-items: center;
}

.app-header .header-item {
  padding: 0 15px;
}

.app-header .header-item.logo {
  font-size: 30px;
  font-weight: 600;
}
.app-content {
  width: 100%;
  /* max-width: 1600px; try to fix the flikering issue when using tab-motion */
  flex: 1;
}

.left-text {
  text-align: left;
  padding-bottom: 20px;
}

.left-align {
  text-align: left;
}

.padded-text {
  margin: 0 0 10px 20px;
}

.title-font {
  font-size: larger;
  margin-bottom: 20px;
}

.small-font {
  font-size: small;
}
 
.button {
  width: 100%;
  margin: 20px 0 !important;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
